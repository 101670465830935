import Vue from 'vue';
import TYPES from '@/types';

// Application
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import GetInvestmentProductsQuery
  from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import InvestorGoalScheduledDistributionWealthFlexibleCommand
  from '@/modules/flagship/investor-goal-scheduled-distribution/sowos-wealth/flexible/application/commands/investor-goal-scheduled-distribution-wealth-flexible-command';

// Domain
import {
  SearchByCustomerDto,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import { WealthFlexibleDepositStateManager } from '@/modules/my-investment/allianz/transaction/domain/state/wealth-flexible-deposit-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class AllianzDepositFlexibleDialogViewModel {
  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.WEALTH_FLEXIBLE_DEPOSIT_STATE_MANAGER)
  private readonly wealth_flexible_deposit_state_manager!: WealthFlexibleDepositStateManager;

  @Inject(TYPES.INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_WEALTH_FLEXIBLE_COMMAND)
  private readonly investor_goal_scheduled_distribution_wealth_flexible_command!: (
    InvestorGoalScheduledDistributionWealthFlexibleCommand);

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.allianz-dashboard.deposits.flexible_deposit_modal';

  readonly view: Vue;

  is_loading = false;

  current_step = 1;

  readonly total_steps = 4;

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  is_any_goal_active = false;

  successful_result = false;

  show_schedule_a_call_modal = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  menu_steps = this.translate('steps_with_balance');

  steps = [
    {
      component: 'FlexibleDeposit',
      visible: true,
      step_number: 1,
    },
    {
      component: 'FlexibleDepositGoalsDistribution',
      visible: true,
      step_number: 2,
    },
    {
      component: 'FlexibleDepositConfirmation',
      visible: true,
      step_number: 3,
    },
    {
      component: 'FlexibleDepositResult',
      visible: true,
      step_number: 4,
    },
  ];

  special_deposit_steps = [
    {
      header: this.translate('steps_special_deposit.0'),
      component: 'SpecialDepositInformation',
      visible: true,
      step_number: 5,
    },
    {
      header: this.translate('steps_special_deposit.1'),
      component: 'AttachYourProof',
      visible: true,
      step_number: 6,
    },
    {
      header: this.translate('steps_special_deposit.2'),
      component: 'FlexibleDepositGoalsDistribution',
      visible: true,
      step_number: 7,
    },
    {
      header: '',
      component: 'SpecialDepositSuccess',
      visible: false,
      step_number: 8,
    },
  ];

  public constructor(view: Vue) {
    this.view = view;
  }

  get show_stepper_header() {
    return this.current_step < 4 || this.show_special_deposit_steps;
  }

  get show_flexible_deposit_steps() {
    return this.current_step < 4;
  }

  get show_special_deposit_steps() {
    return this.current_step >= 5 && this.current_step <= 6;
  }

  get steps_special_deposit() {
    return this.special_deposit_steps.filter((step) => step.header !== '' && step.visible);
  }

  get mount_special_deposit_flow() {
    return this.current_step >= 5 && this.current_step <= 8;
  }

  getOpacityClassToSteps = (current_step_number: number) => ((this
    .current_step === current_step_number) ? '' : 'opacity-header-step');

  nextStep = async () => {
    if (this.current_step >= 5) {
      if (this.current_step === 7 && this.is_any_goal_active) {
        const saved = await this.saveDistributedAmountLinkedGoals();
        if (saved) {
          this.current_step += 1;
        }
      } else {
        this.current_step += 1;
      }
    } else if (this.current_step < this.total_steps) {
      if (this.current_step === 1 && !this.is_any_goal_active) {
        this.current_step += 2;
      } else {
        this.current_step += 1;
      }
    }
  }

  prevStep = () => {
    if (this.current_step > 0) {
      if (this.current_step === 3 && !this.is_any_goal_active) {
        this.current_step -= 2;
      } else {
        this.current_step -= 1;
      }
    }
  }

  restoreState = () => {
    this.wealth_flexible_deposit_state_manager.restore();
  }

  resetProperties = () => {
    this.current_step = 1;
    this.restoreState();
    this.view.$emit('endProcess');
  }

  endProcess = () => {
    this.restoreState();
    this.view.$emit('endProcess');
  }

  changeLoadingStep = (loading: boolean) => {
    this.is_loading = loading;
  }

  specialContributionSelected = () => {
    this.current_step = 5;
  }

  showCalendlyModal = () => {
    this.view.$emit('showCalendlyModal');
  }

  setCorrectMenuSteps = () => {
    if (this.is_any_goal_active) {
      this.menu_steps = this.translate('steps_with_balance');
    } else {
      this.menu_steps = this.translate('steps_without_balance');
      this.special_deposit_steps.splice(2, 1);
      this.special_deposit_steps[2].step_number = 7;
    }
  }

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      const product = investment_products.find((item) => item.name === 'sowos_wealth');
      this.search_by_customer_dto.associated_product_id = product!.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  loadActiveGoals = async () => {
    try {
      const goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      this.is_any_goal_active = goals.length > 0;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_search_by_customer_query'));
    }
  }

  saveDistributedAmountLinkedGoals = async (): Promise<boolean> => {
    try {
      await this.investor_goal_scheduled_distribution_wealth_flexible_command.execute(
        this.wealth_flexible_deposit_state_manager.state.goal_distribution,
      );
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.investor_goal_scheduled_distribution_wealth_flexible_command'));
      return false;
    }
  }

  initialize = async () => {
    await this.loadInvestmentProducts();
    await this.loadActiveGoals();
    this.setCorrectMenuSteps();
  }
}
