














































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import AllianzDepositFlexibleDialogViewModel from '@/vue-app/view-models/allianz-dashboard/deposits/flexible_deposits/allianz-deposit-flexible-dialog-view-model';

@Component({
  name: 'FlexibleDepositDialog',
  components: {
    FlexibleDeposit: () => import('@/vue-app/components/allianz-dashboard/deposits/flexible_deposits/FlexibleDeposit.vue'),
    FlexibleDepositGoalsDistribution: () => import('@/vue-app/components/allianz-dashboard/deposits/flexible_deposits/FlexibleDepositGoalsDistribution.vue'),
    FlexibleDepositConfirmation: () => import('@/vue-app/components/allianz-dashboard/deposits/flexible_deposits/FlexibleDepositConfirmation.vue'),
    FlexibleDepositResult: () => import('@/vue-app/components/allianz-dashboard/deposits/flexible_deposits/FlexibleDepositResult.vue'),
    AttachYourProof: () => import('@/vue-app/components/allianz-dashboard/deposits/special_deposits/AttachYourProof.vue'),
    SpecialDepositInformation: () => import('@/vue-app/components/allianz-dashboard/deposits/special_deposits/SpecialDepositInformation.vue'),
    SpecialDepositSuccess: () => import('@/vue-app/components/allianz-dashboard/deposits/special_deposits/SpecialDepositSuccess.vue'),
  },
})
export default class FlexibleDepositDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  flexible_deposit_modal_model = Vue.observable(
    new AllianzDepositFlexibleDialogViewModel(this),
  );

  endProcess() {
    this.flexible_deposit_modal_model.resetProperties();
  }

  created() {
    this.flexible_deposit_modal_model.initialize();
  }
}
